import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { message, pageRows } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import useVehiculosEnGestoria from '../../../Hooks/Inventario/useVehiculosEnGestoria'
import SubMenuEndoso from './SubMenuEndoso'
import usePendienteDeEndoso from '../../../Hooks/Inventario/usePendienteDeEndoso'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const PendienteEndoso = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        tipoCompra,
        setclaveTipoCompra,
        handleDownloadFile
    } = usePendienteDeEndoso(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuEndoso />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos pendientes de endoso</strong></span>
                    {/* <button className='btn'  onClick={handleDownloadFile}>Exportar Excel</button> */}
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos en contrato listos para el endoso.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PendientesDeEndoso, SubModule.Endoso).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setclaveTipoCompra(e.value) }} placeholder="Tipo compra" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        detailable={ValidatePermissionById(4, Section.PendientesDeEndoso, SubModule.Endoso).Ver}
                        handleDetail={handleUploadFile}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default PendienteEndoso