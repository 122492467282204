import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import useSeguroPagado from '../../../Hooks/Seguros/useSeguroPagado'
import usePolizasVigentes from '../../../Hooks/Seguros/usePolizasVigentes'
const PolizasVigentes = () => {
    const {
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        setAseguradora,
        DataAseguradora,
        handleDownloadExcel,
        selectRef,
        dataCondicionPoliza,
        setcondicionPoliza,
        handleDetail,
        dataEstatus,
        setEstatus
    } = usePolizasVigentes(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Pólizas vigentes</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.PolizasVigentes, SubModule.PolizasVigentes)?.Ver}>
                        <button className='btn' onClick={handleDownloadExcel}>Exportar excel</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a las pólizas de seguros que se encuentran pendientes de pago.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PolizasVigentes, SubModule.PolizasVigentes)?.Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select ref={selectRef} options={DataAseguradora} className='col-12' onChange={(e) => { setAseguradora(e.value) }} placeholder="Aseguradora" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataUsoUnidad} className='col-12' onChange={(e) => { setClaveUsoUnidad(e.value) }} placeholder="Uso unidad" />
                        </div>
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setTipoCompra(e.value) }} placeholder="Tipo compra" />
                        </div> */}
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={dataEstatus} className='col-12' onChange={(e) => { setEstatus(e.value) }} placeholder="estatus" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={dataCondicionPoliza} className='col-12' onChange={(e) => { setcondicionPoliza(e.value) }} placeholder="condición de póliza" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive tbl-scroll'>
                    <DataTable
                        column={columns}
                        data={data}
                        iswidth={true}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        detailable={ValidatePermissionById(4, Section.PolizasVigentes, SubModule.PolizasVigentes)?.Ver}
                        handleDetail={handleDetail}
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default PolizasVigentes