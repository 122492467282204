import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CrearFolderModal from '../../Ventas/ModalCreateFolder'
import useSeguroAutorizado from '../../../Hooks/Seguros/useSeguroAutorizado'
import RowPages from '../../../Components/RowPages'
import useVehiculoConvertido from '../../../Hooks/Inventario/useVehiculoConvertido'
import useExpedienteEnProceso from '../../../Hooks/Inventario/useExpedienteEnProceso'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const ExpedienteEnProceso = () => {
    const { columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        habdleDownloadFile,
        DataSocioComercial,
        tipoCompra,
        setclaveTipoCompra,
        setClaveVendedor,
        handleUploadFile,
        downloadFile
    } = useExpedienteEnProceso(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos en proceso de Expediente</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosExpediente, SubModule.Expediente).Descarga}>
                        <button className='btn' onClick={habdleDownloadFile}>Exportar excel</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde al proceso de la generación de expedientes.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosExpediente, SubModule.Expediente).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setclaveTipoCompra(e.value) }} placeholder="Tipo de compra" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <Select options={DataSocioComercial} className='col-12' onChange={(e) => { setClaveVendedor(e.value) }} placeholder="Externo" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        downtable={ValidatePermissionById(4, Section.VehículosExpediente, SubModule.Expediente).Descarga}
                        handleDownload={downloadFile}
                        detailable={ValidatePermissionById(4, Section.VehículosExpediente, SubModule.Expediente).Ver}
                        handleDetail={handleUploadFile}
                    />
                </div>
            </ValidatePermission>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default ExpedienteEnProceso