import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { message, pageRows } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import SubMenuPoliza from './SubMenuPoliza'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import useReenvioPoliza from '../../../Hooks/Seguros/useReenvioPoliza'

const ReenviarPoliza = () => {
    const { 
        columns,
        usoUnidad,
        tipoCompra,
        setStr,
        str,
        setLoading,
        loading,
        setClaveUsoUnidad,
        setClaveTipoCompra,
        data,
        page,
        pageCount,
        handlePageClick,
        handlechekedItem,
        handleViewdocs
    } = useReenvioPoliza(toast);

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuPoliza />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Reenvío de póliza</strong></span>
                    {/* <ValidatePermission isActive={ValidatePermissionById(4, Section.ReenvioPolizas, SubModule.EmisiónDePolizas).Ver}>
                        <button className='btn' onClick={handleUploadFile}>Emitir póliza</button>
                    </ValidatePermission> */}
                </div>
                <span className='mt-2'>A continuación, se presentan los vehículos con seguro cotizado pendientes de emitir su póliza.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculoUtilitarioPoliza, SubModule.EmisiónDePolizas).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={usoUnidad} className='col-12' onChange={(e) => { setClaveUsoUnidad(e.value) }} placeholder="Uso unidad" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setClaveTipoCompra(e.value) }} placeholder="Tipo compra" />
                        </div>
                    </div>
                </section>
                {/* <RowPages
                    setpageRows={setpageRows}
                /> */}
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={page - 1}
                        handleDocuments={handleViewdocs}
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default ReenviarPoliza