import React from 'react'
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select'
import useVentaVehiculos from '../../../Hooks/Ventas/Prealta/useVentaVehiculos'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import RowPages from '../../../Components/RowPages'
import ModalConfirmVehiculoConvertido from '../../Inventario/VehiculoConvertido/ModalConfirmVehiculoConvertido'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import SubMenuVentacontado from './SubMenuVentaContado'
import useVehiculoVentaContado from '../../../Hooks/Inventario/useVehiculoVentaContado'
import useVehiculoVentaContadoSeguimiento from '../../../Hooks/Inventario/useVehiculoVentaContadoSeguimiento'
import useVehiculoVentaContadoConcluido from '../../../Hooks/Inventario/useVehiculoVentaContadoConcluido'
const VehiculoVentaContadoConcluido = () => {
    const { item, isOpenModalClave, isOpenModalClaveCambioProd } = useSelector((state) => state.quickActionsSlice);
    const { columns,
        columnsH,
        data,
        handleEdit,
        navigate,
        handlePageClick,
        currenPage,
        pageCount,
        setTipoAd,
        setStr,
        loading,
        dataTipoAdquisicion,
        handleCreateFolder,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        setIsOpenModal,
        dataModal,
        IsOpenModal,
        handlePageClickH,
        currenPageH,
        dataUtilitario,
        setUtilitarioId,
        handleViewdocs,
        handlechekedItem,
        setpageRows,
        setFechaInicio,
        setFechaFin,
        dataStatus,
        setClaveStatus,
        dataEtapa,
        setClaveEtapa
    } = useVehiculoVentaContadoConcluido(toast);

    const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuVentacontado />
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Seguimiento de Vehículos Concluidos</strong></span>
                    {/* <ValidatePermission isActive={ValidatePermissionById(4, Section.VentacontadoConcluido, SubModule.VentaContado).Agregar}>
                        <div>
                            <button className='btn px-4' onClick={() => {
                                navigate(`/inventario/venta/prealta/0`, {
                                    state: {
                                        page: 'Vehiculo',
                                        title: "Nuevo Vehiculo",
                                        etapa: 0,
                                        ventaContado:true
                                    },  
                                });
                            }}>Nuevo vehículo</button>
                        </div>
                    </ValidatePermission> */}
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos concluidos.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VentacontadoConcluido, SubModule.VentaContado)?.Ver}>
                <section className='mx-4'>
                    <div className='row col-12'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>                            
                            <Select options={dataEtapa} className='col-12' onChange={(e) => { setClaveEtapa(e.value) }} placeholder="Etapa" />                           
                        </div>                        
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label></label>
                            <Select options={dataTipoAdquisicion} className='col-12' onChange={(e) => { setTipoAd(e.value) }} placeholder="Tipo Compra" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label></label>
                            <Select options={dataStatus} className='col-12' onChange={(e) => { setClaveStatus(e.value) }} placeholder="Estatus" />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <label>Fecha Inicio</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechaInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <label>Fecha fin</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechaFin(e.target.value) }} />
                        </div> */}
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />

                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        // edita   ble={ValidatePermissionById(4, Section.VentacontadoConcluido, SubModule.VentaContado).Actualizar}
                        handleEdit={handleEdit}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick} z
                        currentPage={currenPage - 1}
                        // actions={ValidatePermissionById(4, Section.VehiculosDataMovil, SubModule.Datamovil).Ver}
                        type={2}
                        // checkbox={true}
                        handleCheck={handlechekedItem}
                        handleAction={handleCreateFolder}
                        handleDocuments={handleViewdocs}
                        tagCovert={true}
                    />
                </div>
            </ValidatePermission>
            {/* {
        isOpenDocs &&
        <VehiculoUtilitarioModal
          isOpen={isOpenDocs}
          setIsOpen={setIsOpenDocs}
          item={dataVehiculo}
          handleGetAll={habdleGetAll}
          message={message}
          toast={toast}
        />
      } */}
            {
                IsOpenModal &&
                <Modal isOpen={IsOpenModal} color='#fff'>
                    <section className='my-4 mx-4' style={{ width: '50rem' }}>
                        <div className='d-flex justify-content-center fs-6'>
                            <span className='fs-5'><strong>Historial Utilitario</strong></span>

                        </div>

                        <DataTable
                            column={columnsH}
                            data={dataModal}
                            pageCount={pageCount}
                            handlePageClick={handlePageClickH}
                            currentPage={currenPageH - 1}
                        />
                        <section className='my-3 d-flex justify-content-end'>
                            <fieldset className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
                            {/* <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button> */}
                        </section>

                    </section>
                </Modal>
            }
            {/* {
        isOpenModalClave &&
        <ModalClaveVehiculo
          isOpenModalClave={isOpenModalClave}
          item={item}
        />
      } */}
            {
                isOpenModalClaveCambioProd &&
                <ModalConfirmVehiculoConvertido
                    toast={toast}
                    isOpenModalClave={isOpenModalClaveCambioProd}
                    item={item}
                />
            }
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default VehiculoVentaContadoConcluido