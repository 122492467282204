import React from 'react'
import DataTable from '../../Components/datatable/DataTable'
import Select from 'react-select';
import useUsuario from '../../Hooks/Usuarios/useUsuario';
import Modal from '../../Components/Modal/Modal';
import Spinner from '../../Components/Loadig';
import ErrorModal from '../../Components/Modal/ErrorModal';
import SuccessModal from '../../Components/Modal/SuccessModal';
import { pageRows } from '../../Utils/functions';
import RowPages from '../../Components/RowPages';
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission';
import { Section, SubModule } from '../../Auth/Authorization';

const UsuarioLst = () => {
  let { columns,
    data,
    pageCount,
    dataRol,
    statusData,
    setIdRol,
    setStatus,
    setStr,
    loading,
    setModalMessage,
    modalMessage,
    handlePageClick,
    handleDetails,
    handleNewuser,
    page,
    setpageRows } = useUsuario();

  return (
    <>
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
      <Modal isOpen={modalMessage.isOpen} color='#fff'>
        {
          modalMessage.type === 1 &&
          <ErrorModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        }
        {
          modalMessage.type === 2 &&
          <SuccessModal
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
          />
        }

      </Modal>
      <section className='mx-5 py-4'>
        <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Usuarios registrados</strong></span>
          <div>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.UsuariosRegistrados, SubModule.Usuarios).Agregar} >
              <button className='btn px-4' onClick={handleNewuser}>Nuevo usuario</button>
            </ValidatePermission>
          </div>
        </div>
      </section>
      <section className='mt-3 mx-4'>
        <div className='col-lg-4 col-sm-12 d-flex'>
          <div className="col-6 mx-1  inner-addon right-addon">
            <i className="glyphicon fas fa-search"></i>
            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
          </div>
          <div className='col-6 d-flex'>
            <Select options={dataRol} className='col-12 mx-1' onChange={(e) => { setIdRol(e.value) }} placeholder="Roles" />
            <Select options={statusData} className='col-12 mx-1' onChange={(e) => { setStatus(e.value) }} placeholder="Status" />
          </div>
        </div>
      </section>
      <ValidatePermission
       isActive={ValidatePermissionById(4, Section.UsuariosRegistrados, SubModule.Usuarios).Ver} 
      >
        <RowPages
          setpageRows={setpageRows}
        />
        <div className='mx-4 table-responsive'>
          <DataTable
            column={columns}
            data={data}
            pageCount={pageCount}
            detailable={ValidatePermissionById(4, Section.UsuariosRegistrados, SubModule.Usuarios).Actualizar}            
            handleDetail={handleDetails}
            paginate={true}
            handlePageClick={handlePageClick}
            currentPage={page - 1}
          />
        </div>
      </ValidatePermission>
    </>
  )
}

export default UsuarioLst