import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { message } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CrearFolderModal from '../../Ventas/ModalCreateFolder'
import useSeguroAutorizadoPoliza from '../../../Hooks/Seguros/useSeguroAutorizadoPoliza'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import SubMenuCancelacionSeguro from './SubMenuCancelacionSeguro'
import useSeguroPagado from '../../../Hooks/Seguros/useSeguroPagado'
import TotalesSeguroPagadoModal from './TotalesSeguroPagadoModal'
const SeguroPagado = () => {
    const {    
        columns,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        setTipoCompra,
        setAseguradora,
        DataAseguradora,
        handleDownloadExcel,
        openModalTotales,
        setopenModalTotales,
        IdsExportar,
        SetAseradoraLabel,
        aseradoraLabel,
        selectRef
    } = useSeguroPagado(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuCancelacionSeguro />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Póliza pagada</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.PolizaPagada, SubModule.CancelacionDeSeguro)?.Ver}>
                        <button className='btn' onClick={handleDownloadExcel}>Generar reporte de cancelación</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a la gestión de todos los seguros pagados.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PolizaPagada, SubModule.CancelacionDeSeguro)?.Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataUsoUnidad} className='col-12' onChange={(e) => { setClaveUsoUnidad(e.value) }} placeholder="Uso unidad" />
                        </div>
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setTipoCompra(e.value) }} placeholder="Tipo compra" />
                        </div> */}
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select ref={selectRef} options={DataAseguradora} className='col-12' onChange={(e) => { setAseguradora(e.value)}} placeholder="Aseguradora" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        // editable={true}
                        // handleEdit={handleEdit}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        checkbox={true}
                        handleCheck={handlechekedItem}
                    />
                </div>
            </ValidatePermission>
            {
                openModalTotales &&
                <TotalesSeguroPagadoModal
                    isOpen={openModalTotales}
                    setIsOpen={setopenModalTotales}
                    item={IdsExportar}
                    handleGetAll={()=>{selectRef.current.setValue([], 'clear')}}
                    toast={toast}                  
                />
            }
        </>
    )
}

export default SeguroPagado