import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import UploadFile from '../../../Components/UploadFile'
import useProcesoDePagoPD from '../../../Hooks/Seguros/useProcesoDePagoPD'
import SubMenuComisiones from './SubMenuComisiones'
import useProcesoDePagoComisiones from '../../../Hooks/Seguros/useProcesoDePagoComisiones'
import ModalConfirmProcesoPagoComision from './ModalConfirmProcesoPagoComision'
const ProcesoPagoComision = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        dataVehiculo,
        habdleGetAll,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra,
        columnsDetail,
        DataAseguradora,
        setAseguradora,
        setFechInicio,
        setFechFin,
        setIsOpenModalFile,
        isOpenModalFile,
        handlePocesarInfo,
        openModalConfirm,
        setOpenModalConfirm,
        dataPD,
        handleReimprimirReporte
    } = useProcesoDePagoComisiones(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuComisiones />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Seguro proceso de pago de comisión</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.ProcesoDePagoComision, SubModule.Comisiones).Ver}>
                        <button className='btn' onClick={() => { setIsOpenModalFile(true) }}>Comisiones pagadas</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a la gestión de los seguros en proceso de pago de comisión.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.ProcesoDePagoComision, SubModule.Comisiones).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => {e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>                                            
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1 mt-4'>
                            {/* <label>Aseguradora</label> */}
                            <Select options={DataAseguradora} className='col-12' onChange={(e) => { setAseguradora(e.value) }} placeholder="Aseguradora" />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Inicio</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1">
                            <label>Fecha Fin</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechFin(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        masterDetail={true}
                        columnDetail={columnsDetail}
                        downtable={true}    
                        labelAutorizar={"Descargar Reporte Pendiente de pago"}  
                        handleDownload={handleReimprimirReporte}  
                    />
                </div>
            </ValidatePermission>
            {
                isOpenModalFile &&
                <UploadFile
                    isOpenModal={isOpenModalFile}
                    setIsOpenModal={setIsOpenModalFile}
                    handleSubmit={handlePocesarInfo}
                />
            }
            {
                openModalConfirm &&
                <ModalConfirmProcesoPagoComision
                    isOpen = {openModalConfirm}
                    setIsOpen = {setOpenModalConfirm}
                    handleGetAll = {habdleGetAll}
                    toast = {toast}
                    item = {dataPD}
                />
            }
        </>
    )
}

export default ProcesoPagoComision