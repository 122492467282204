import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { TextField } from '../../../Components/TextField';
import { SelectField } from '../../../Components/SelectField';
import VehiculoService from '../../../Services/Vehiculo/vehiculo';
import Catalogo from '../../../Services/Catalogos/Catalogo';
import VentaVehiculoService from '../../../Services/Venta/Vehiculo/ventaVehiculoService';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '../../../Components/Modal/Modal';
import Spinner from '../../../Components/Loadig';
import SubMenuPrealta from './subMenuPrealta';
import { useDispatch, useSelector } from 'react-redux';
import handlePhonNumberFormatter, { Normalize, message, FormatNumberMoney } from '../../../Utils/functions';
import SocioComercialServices from '../../../Services/Inventario/SocioComercialService';
import SeguroSinCotizarService from '../../../Services/Seguros/seguroSinCotizarService';
import AuthLogin from '../../../Utils/AuthLogin';

const auth = new AuthLogin();
const AddEditVehiculo = ({ item = null }) => {
    const { state } = useLocation();
    const role = auth.getSesionValue("role");
    const socioComercialId = auth.getSesionValue("socioComercialId");    
    const navigate = useNavigate();
    let { id } = useParams();
    const { value } = useSelector((state) => state.navigaSlicePrealta);
    const dispatch = useDispatch();
    const refFormik = useRef();
    let initial = {
        vin: "",
        claveMarca: "",
        claveModelo: "",
        claveYear: "",
        claveVersion: "",
        condicionId: "",
        tipoAdquisicionId: "",
        tipoComisionId: "",
        comision: "",
        fechaAdquisicion: "",
        tipoCompraId: state?.ventaContado ? "539ac831-8ab2-4fa2-af95-02723c17ed25" : role==="DMSocioComercial" ? "9efb7ed4-f51d-46df-8668-57c89727226b" :"",
        proveedor: "",
        telProveedor: "",
        emailProveedor: "",
        nombrePago: "",
        tipoPagoId: "",
        numeroPago: "",
        tipoFacturaId: "",
        externoId: "",
        status: true,
        costo: "",
        userId: "",
        isconsignacion: false,
        isMontoFijo: false,
        usrSocioComercialId: role === "DMSocioComercial" ? socioComercialId : "",
        isSocioComercial: role === "DMSocioComercial" ? true :false,
        esSocioIndependiente: role === "DMSocioComercial" ? true :false,
        esRolSociocomercial: (role === "Socio Comercial" || role === "Administrador" || role === 'DMSocioComercial') ? true : false,
        numSolicitud: "",
        usoUnidadId: "",
        ventaContado: state?.ventaContado,
        vehiculoEnCredito: state?.vehiculoEnCredito || false        
    }

    const [initialValue, setInitialValue] = useState(initial);

    const validate = Yup.object({
        vin: Yup.string()
            .required("El campo es requerido.")
            .min(17, "El campo debe contener 17 caracteres.")
            .max(17, "El campo debe contener 17 caracteres.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        claveMarca: Yup.string()
            .required("El campo es requerido."),
        claveModelo: Yup.string()
            .required("El campo es requerido."),
        claveYear: Yup.string()
            .required("El campo es requerido."),
        claveVersion: Yup.string()
            .required("El campo es requerido."),
        condicionId: Yup.string()
            .required("El campo es requerido."),
        tipoAdquisicionId: Yup.string()
            .required("El campo es requerido."),
        tipoComisionId: Yup.string()
            .when("isconsignacion", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        usrSocioComercialId: Yup.string()
            .when("isSocioComercial", {
                is: true,
                then: Yup.string().required("El campo es requerido.")
            }),
        costo: Yup.string()
            .required("El campo es requerido."),
            // .positive("Ingrese un valor positivo.")
            // .typeError("Ingrese valores numericos"),
        comision: Yup.string()
            .when(['isconsignacion', 'isMontoFijo'], (isconsignacion, montoFijo) => {
                if (isconsignacion) {
                    if (montoFijo) {
                        return Yup.string()
                            .required("El campo es requerido.")
                            // .positive("Ingrese un valor positivo.")
                            // .typeError("Ingrese valores numericos")
                    } else {
                        return Yup.string()
                            .required("El campo es requerido.")
                            // .matches("^[0-9]{0,2}\.[0-9]{0,2}$", 'Se requiere formato correcto (Ejemplo: 12.45, del 00.01 al 99.99)')
                            // .test({
                            //     name: 'is-sku',
                            //     skipAbsent: true,
                            //     test(value, ctx) {
                            //         if (value < 1) {
                            //             return ctx.createError({ message: 'ingrese un valor positivo ' })
                            //         }
                            //         if (value >= 100) {
                            //             return ctx.createError({ message: 'ingrese un valor entre 00.01 y 99.99' })
                            //         }
                            //         return true
                            //     }
                            // })
                    }
                }
            }),
        numSolicitud: Yup.string()
            .when(["esRolSociocomercial", "esSocioIndependiente"], (esRolSociocomercial, esSocioIndependiente) => {
                if (esRolSociocomercial && esSocioIndependiente) {
                    return Yup.string()
                        .required("El campo es requerido.")
                        .trim("El texto no debe empezar ni terminar con espacios")
                        .strict(true)
                        .matches(
                            "^[0-9-]+(?: [0-9-]+)*$",
                            "Ingrese numeros y guiones"
                        )
                }
            }),
        usoUnidadId: Yup.string()
            .when(["esRolSociocomercial", "esSocioIndependiente"], (esRolSociocomercial, esSocioIndependiente) => {
                if (esRolSociocomercial && esSocioIndependiente) {
                    return Yup.string()
                        .required("El campo es requerido.")
                }
            }),

        fechaAdquisicion: Yup.string()
            .required("El campo es requerido."),
        tipoCompraId: Yup.string()
            .required("El campo es requerido."),
        proveedor: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        telProveedor: Yup.string()
            .required("El campo es requerido.")
            .min(14, "Se requiere formato correcto (Ejemplo: (81)-2514–7080)"),
        // .matches('^\(\d{2}\)-\d{4}-\d{4}$', 'Se requiere formato correcto (Ejemplo: (81)-2514–7080)') ,       
        emailProveedor: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true)
            .email("Ingrese un email valido."),
        tipoPagoId: Yup.string()
            .required("El campo es requerido."),
        numeroPago: Yup.string()
            .required("El campo es requerido."),
        // .trim("El texto no debe empezar ni terminar con espacios")
        // .strict(true),
        tipoFacturaId: Yup.string()
            .required("El campo es requerido.")
            .trim("El texto no debe empezar ni terminar con espacios")
            .strict(true),
        externoId: Yup.string()
            .required("El campo es requerido."),
    });
    const [valueSelected, setValueSelected] = useState(1); //1.- Prealta, 2.- Alta, ,3.- Valoracion 
    const [dataMarca, setDataMarca] = useState([]);
    const [dataModelo, setDataModelo] = useState([]);
    const [dataYear, setDataYear] = useState([]);
    const [dataVersion, setDataVersion] = useState([]);
    const [dataTipoAdquisicion, setDataTipoAdquisicion] = useState([]);
    const [dataTipoPago, setDataTipoPago] = useState([]);
    const [dataSocioComercial, setDataSocioComercial] = useState([]);
    const [dataTipoComision, setDataTipoComision] = useState([]);
    const [dataTipoCompra, setDataTipoCompra] = useState([]);
    const [dataTipoFactura, setDataTipoFactura] = useState([]);
    const [dataCondicion, setDataCondicion] = useState([]);
    const [loading, setLoading] = useState(false);
    const [claveMarca, setClaveMarca] = useState("");
    const [claveModelo, setClaveModelo] = useState("");
    const [claveYear, setClaveYear] = useState("");
    const [activeComision, setActiveComision] = useState(false);
    const [isconsignacion, setIsconsignacion] = useState(false);
    const [DataUsoUnidad, setDataUsoUnidad] = useState([]);

    const [progressBar, setProgressBar] = useState(0);
    const [changeForm, setChangeForm] = useState(1);
    const [disabledFields, setDisabledFields] = useState(true);

    useEffect(() => {
        handleGetAllMarca();
        handleGetTipoAdquisicio();
        handleGetTipoPago();
        handleGetSocioComercial();
        handleGetTipoComision();
        handleGetTipoCompra();
        handleGetTipoFactura();
        handleGetCondicion();
        handleGetAllUsoUnidad();
        if (id != 0) {
            handleGetById();
            handleChageProgress(refFormik.current.errors);
        }

        if(id === '0'){
            setDisabledFields(false);
        }
    }, [])
    
    const handleGetById = async () => {
        setLoading(true);
        await VentaVehiculoService.getlAllVehiculoPrealtaById(id)
            .then(res => {
                let item = res.data.data;
                console.log("edit", item)
                let initial = {
                    vin: item?.vin ?? "",
                    claveMarca: item?.claveMarca ?? "",
                    claveModelo: item?.claveModelo ?? "",
                    claveYear: item?.claveYear ?? "",
                    claveVersion: item?.claveVersion ?? "",
                    condicionId: item?.condicionId ?? "",
                    tipoAdquisicionId: item?.tipoAdquisicionId ?? "",
                    tipoComisionId: item?.tipoComisionId ?? "",
                    comision: FormatNumberMoney(item?.comision ?? ""),
                    fechaAdquisicion: item?.fechaAdquisicion.split("T")[0] ?? "",
                    tipoCompraId: item.tipoCompraId ?? "",
                    proveedor: item?.proveedor ?? "",
                    telProveedor: handlePhonNumberFormatter(item?.telProveedor) ?? "",
                    emailProveedor: item?.emailProveedor ?? "",
                    nombrePago: item?.nombrePago ?? "",
                    tipoPagoId: item?.tipoPagoId ?? "",
                    numeroPago: item?.numeroPago ?? "",
                    tipoFacturaId: item?.tipoFacturaId ?? "",
                    externoId: item?.externoId ?? "",
                    status: item?.status ?? true,
                    costo: FormatNumberMoney(item?.costo ?? ""),
                    userId: item?.userId ?? "",
                    isconsignacion: item?.tipoAdquisicion === "Consignación" ? true : false,
                    isMontoFijo: item?.tipoComision === "Monto Fijo" ? true : false,
                    isSocioComercial: item.tipoCompra === "Socio Comercial" ? true : role === "DMSocioComercial" ? true :false,
                    esSocioIndependiente: (item.tipoCompra === "Socio Comercial" || item.tipoCompra === "Independiente") ? true : false,
                    usrSocioComercialId: item?.usrSocioComercialId ?? "",
                    esRolSociocomercial: (role === "Socio Comercial" || role === "Administrador" || role === "DMSocioComercial") ? true : false,
                    numSolicitud: item?.numSolicitud ?? "",
                    usoUnidadId: item?.usoUnidadId ?? "",
                    ventaContado: state?.ventaContado
                }
                setIsconsignacion(item.tipoAdquisicion === "Consignación" ? true : false);
                handleChangeMarca(item.claveMarca);
                handleChangeModelo(item.claveModelo, item.claveMarca);
                handleChangeYear(item.claveYear, item.claveMarca, item.claveModelo);
                setInitialValue(initial);
                setLoading(false);
            }).catch(e => {
                setLoading(false);
            })
    }
    const handleGetAllUsoUnidad = async () => {
        setLoading(true);
        await SeguroSinCotizarService.getlAllUsoUnidad()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.usoUnidadId,
                        label: item.usoUnidad
                    })
                });
                setDataUsoUnidad(NewData);
                setLoading(false);
            }).catch(e => {
                // setLoading(false);
            })
    }
    const handleGetAllMarca = async () => {
        await VehiculoService.getAllMarca()
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveMarca,
                        label: item.nombreMarca
                    })
                });
                setDataMarca(NewData);
            }).catch(e => {
            })
    }
    const handleChangeMarca = async (value) => {
        setClaveMarca(value);
        setDataModelo([]);
        setDataYear([]);
        setDataVersion([]);
        await VehiculoService.getAllModeloById(value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveModelo,
                        label: item.nombreModelo
                    })
                });
                setDataModelo(NewData);
            }).catch(e => {
            })
    }
    const handleChangeModelo = async (value, marca = "") => {
        setClaveModelo(value);
        if (value === "")
            setDataYear([]);
        await VehiculoService.getAllYearById(marca === "" ? claveMarca : marca, value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveYear,
                        label: item.nombreYear
                    })
                });
                setDataYear(NewData);
            }).catch(e => {
            })
    }
    const handleChangeYear = async (value, marca = "", modelo = "") => {
        setClaveYear(value);
        await VehiculoService.getAllVersionById(marca === "" ? claveMarca : marca, modelo === "" ? claveModelo : modelo, value)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    NewData.push({
                        value: item.claveVersion,
                        label: item.nombreVersion
                    })
                });
                setDataVersion(NewData);
            }).catch(e => {
            })
    }

    const handleGetTipoAdquisicio = async () => {
        await Catalogo.getdataTabla("TipoAdquisicion", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (state?.ventaContado) {
                        if (item?.status === true && (Normalize(item?.tipoAdquisicion) !== 'consignacion')) {
                            NewData.push({
                                value: item.tipoAdquisicionId,
                                label: item.tipoAdquisicion
                            })
                        }
                    } else {
                        if (item?.status === true) {
                            NewData.push({
                                value: item.tipoAdquisicionId,
                                label: item.tipoAdquisicion
                            })
                        }
                    }
                });
                setDataTipoAdquisicion(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoPago = async () => {
        await Catalogo.getdataTabla("TipoPago", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status === true) {
                        NewData.push({
                            value: item.tipoPagoId,
                            label: item.tipoPago
                        })
                    }
                });
                setDataTipoPago(NewData);
            }).catch(e => {

            })
    }
    const handleGetSocioComercial = async () => {
        await SocioComercialServices.getAllSocioComercialList()
            .then(res => {
                let NewData = [];

                res.data.data?.map(item => {
                    NewData.push({
                        value: item.socioComercialId,
                        label: item.nombre
                    })
                });
                setDataSocioComercial(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoComision = async () => {
        await Catalogo.getdataTabla("TipoComision", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status === true) {
                        NewData.push({
                            value: item.tipoComisionId,
                            label: item.tipoComision
                        })
                    }
                });
                setDataTipoComision(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoCompra = async () => {
        await Catalogo.getdataTabla("TipoCompra", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status === true) {
                        NewData.push({
                            value: item.tipoCompraId,
                            label: item.tipoCompra
                        })
                    }
                });
                setDataTipoCompra(NewData);
            }).catch(e => {

            })
    }
    const handleGetTipoFactura = async () => {
        await Catalogo.getdataTabla("TipoFactura", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status === true) {
                        NewData.push({
                            value: item.tipoFacturaId,
                            label: item.tipoFactura
                        })
                    }
                });
                setDataTipoFactura(NewData);
            }).catch(e => {

            })
    }
    const handleGetCondicion = async () => {
        await Catalogo.getdataTabla("Condicion", 1, 100)
            .then(res => {
                let NewData = [];
                res.data.data?.map(item => {
                    if (item.status === true) {
                        NewData.push({
                            value: item.condicionId,
                            label: item.condicion
                        })
                    }
                });
                setDataCondicion(NewData);
            }).catch(e => {

            })
    }
    const handleChangeTAdq = (value, valuesState) => {
        if (value.label === "Consignación") {
            setIsconsignacion(true);
            setInitialValue({ ...valuesState, isconsignacion: true, isMontoFijo: true, tipoAdquisicionId: value.value, tipoComisionId: "1f7ec0db-93e6-4782-a04a-6410682795ca", comision: "1.00" });
        } else {
            setIsconsignacion(false);
            setInitialValue({ ...valuesState, isconsignacion: false, isMontoFijo: false, tipoAdquisicionId: value.value, tipoComisionId: "" });
        }
    }
    const handleAddEdit = async (value) => {
        // refbtn.current.disabled = true;
        if (id == 0) {
            setLoading(true);
            await VentaVehiculoService.addVehiculoPrealta(value)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        toast.success(message("¡Correcto.!", res.data.data.mensaje));
                        setLoading(false);
                        if (state?.esSocioComercial)
                            navigate('/inventario/sociocomercial');
                        else if (state?.ventaContado)
                            navigate('/inventario/venta/ventacontado');
                        else
                            navigate('/inventario/vehiculo');
                    } else {
                        setLoading(false);
                        toast.error(message("¡Error.!", res.data.data.mensaje));
                    }
                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        } else {
            setLoading(true);
            await VentaVehiculoService.updateVehiculoPrealt(id, value)
                .then(res => {
                    if (res.data.data.ban === 1) {
                        toast.success(message("¡Correcto.!", res.data.data.mensaje));
                        setLoading(false);
                        if (state?.esSocioComercial)
                            navigate('/inventario/sociocomercial');
                        else if (state?.ventaContado)
                            navigate('/inventario/venta/ventacontado');
                        else
                            navigate('/inventario/vehiculo');
                    } else {
                        setLoading(false);
                        toast.error(message("¡Error.!", res.data.data.mensaje));
                    }

                }).catch(e => {
                    setLoading(false);
                    if (e.response !== undefined)
                        toast.error(message("¡Error.!", e.response.data.message));
                    else
                        toast.error(message("¡Error.!", e.message));
                })
        }
    }
    const handleChange = (value, valuesState) => {
        setActiveComision(value.value !== "" ? true : false);
        if (value.label === "Monto Fijo")
            setInitialValue({ ...valuesState, isMontoFijo: true, tipoComisionId: value.value, comision: "" });
        else
            setInitialValue({ ...valuesState, isMontoFijo: false, tipoComisionId: value.value, comision: "" });


    }
    const handleChangeTipoCompra = (value, valuesState) => {
        // setActiveComision(value.value !== "" ? true : false);
        if (value.label === "Socio Comercial")
            setInitialValue({ ...valuesState, isSocioComercial: true, esSocioIndependiente: true, tipoCompraId: value.value, usrSocioComercialId: "" });
        else if (value.label === "Independiente")
            setInitialValue({ ...valuesState, isSocioComercial: false, esSocioIndependiente: true, tipoCompraId: value.value, usrSocioComercialId: "" });
        else
            setInitialValue({ ...valuesState, isSocioComercial: false, esSocioIndependiente: false, tipoCompraId: value.value, usrSocioComercialId: "" });

    }

    const handleChageProgress = (erros) => {
        let xx = Object.keys(erros).length;
        let totalCampos = 16;
        let porcentaje = (xx * 100) / totalCampos;
        let res = 100 - porcentaje;
        setProgressBar(res);
    }

    const [disabledByVIN, setDisabledByVIN] = useState(false);
    const handleValidaVINOnPreAlta = async (value) => {
        setLoading(true);
        await VentaVehiculoService.BuscarVINPreAlta(value)
        .then(resp => {
            if(resp.data.existe){
                setDisabledByVIN(true);
                toast.error(message(resp.data.mensaje));
            }
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => setLoading(false));
    }

    // const message = (title, message) => <p style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></p>
    return (
        <Formik
            innerRef={refFormik}
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values) => {
                handleAddEdit(values);
                // console.log(values)
            }}
        >
            {({ isValid, values, setFieldValue, errors, }) => (
                <>
                    <Toaster
                        position="top-right"
                        toastOptions={{
                            success: {
                                style: {
                                    background: '#47a066',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #2f7246'
                                },
                            },
                            error: {
                                style: {
                                    background: '#d53f3f',
                                    color: '#FFFF',
                                    borderLeft: '10px solid #ac241a'
                                },
                            },
                        }}
                    />
                    <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
                    <header className='heder-subtitle d-flex justify-content-start align-items-center border-bottom px-2'>
                        <i className="bx bxs-chevron-left mx-2" onClick={() => {
                            if (state?.esSocioComercial)
                                navigate('/inventario/sociocomercial');
                            else if (state?.ventaContado)
                                navigate('/inventario/venta/ventacontado');
                            else
                                navigate('/inventario/vehiculo')
                        }}></i>
                        Regresar a listado general de vehículos
                    </header>
                    <div className='row m-0' style={{ height: '89.5vh' }}>
                        <SubMenuPrealta
                            id={id}
                            state={state}
                            progressBar={progressBar}
                        />
                        <section className='col-10'>
                            <div style={{ marginLeft: '2rem' }}>
                                {
                                    value === 1 &&
                                    <Form>
                                        <section className='d-flex justify-content-between py-2'>
                                            <span className='fs-5'><strong>Información del vehiculo</strong></span>
                                            {
                                                (id !== '0' && !state.vehiculoEnCredito && disabledFields) &&
                                                <fieldset className='btn mx-2' disabled={id === '0' && disabledByVIN} onClick={()=>{setDisabledFields(false)}}>Editar</fieldset>
                                            }
                                            {
                                                (!state.vehiculoEnCredito && !disabledFields) &&
                                                <button className='btn mx-2' disabled={id === '0' && disabledByVIN} type="submit" >Guardar</button>
                                            }
                                            {
                                                state.vehiculoEnCredito &&
                                                <fieldset className='btn btn-light secondary d-flex align-items-center mx-2'><i className="icon-dark bx bx-hide mx-1" ></i>Modo lectura</fieldset>
                                            }
                                        </section>
                                        <div className='my-2 mt-4'>
                                            <p> <strong>Datos Generales</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="VIN" label="VIN:" name="vin" type="text" holder="Escribe" 
                                                    disabled={disabledFields}
                                                    onBlur={(event) => {
                                                        handleValidaVINOnPreAlta(event.target.value);
                                                    }}
                                                    onChange={(event) => {
                                                        setFieldValue("vin", event.target.value);
                                                        handleChageProgress(errors, isValid);
                                                        setFieldValue("externoId", event.target.value.substring(event.target.value.length - 6));
                                                        setDisabledByVIN(false);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="IdExterno" label="ID Externo:" name="externoId" type="text" disabled={disabledByVIN || disabledFields} holder="Escribe" onChange={(event) => {
                                                        setFieldValue("externoId", event.target.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="Marca" label="Marca:" name="claveMarca" disabled={disabledByVIN || disabledFields} items={dataMarca} onChange={(event) => {
                                                        setFieldValue("claveMarca", event.value);
                                                        handleChangeMarca(event.value);
                                                        setFieldValue("claveModelo", "");
                                                        setFieldValue("claveYear", "");
                                                        setFieldValue("claveVersion", "");
                                                        handleChageProgress(errors, isValid);

                                                    }} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="Modelo" label="Modelo:" name="claveModelo" disabled={disabledByVIN || disabledFields} items={dataModelo} onChange={(event) => {
                                                        setFieldValue("claveModelo", event.value);
                                                        handleChangeModelo(event.value);
                                                        setFieldValue("claveYear", "");
                                                        setFieldValue("claveVersion", "");
                                                        handleChageProgress(errors, isValid);

                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="Anio" label="Año:" name="claveYear" disabled={disabledByVIN || disabledFields} items={dataYear} onChange={(event) => {
                                                        setFieldValue("claveYear", event.value);
                                                        handleChangeYear(event.value);
                                                        setFieldValue("claveVersion", "");
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="Version" label="Versión:" name="claveVersion" disabled={disabledByVIN || disabledFields} items={dataVersion} onChange={(event) => {
                                                        setFieldValue("claveVersion", event.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className='mb-3 row'>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="Condicion" label="Condición:" name="condicionId" disabled={disabledByVIN || disabledFields} items={dataCondicion} onChange={(event) => {
                                                        setFieldValue("condicionId", event.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                {
                                                    (values.esRolSociocomercial && values.esSocioIndependiente) &&
                                                    <>
                                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                                            <TextField id="noSolicitud" label="No de Solicitud:" disabled={disabledByVIN || disabledFields} name="numSolicitud" type="text" holder="Escribe" />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6 col-lg-3">
                                                            <SelectField id="usoUnidad" label="Uso de unidad:" disabled={disabledByVIN || disabledFields} name="usoUnidadId" items={DataUsoUnidad} onChange={(event) => {
                                                                setFieldValue("usoUnidadId", event.value);
                                                                handleChageProgress(errors, isValid);
                                                            }} />
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <p> <strong>Adquisición</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="FechaAdquisicion" label="Fecha de adquisición:" name="fechaAdquisicion" disabled={disabledByVIN || disabledFields} type="date" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="TipoAdquisicion" label="Tipo adquisición:" name="tipoAdquisicionId" disabled={disabledByVIN || disabledFields} items={dataTipoAdquisicion} onChange={(event) => {
                                                        handleChangeTAdq(event, values);
                                                        setFieldValue("tipoAdquisicionId", event.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                {
                                                    isconsignacion &&
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        <SelectField id="TipoComision" label="Tipo comisión:" name="tipoComisionId" disabled={disabledByVIN || disabledFields} items={dataTipoComision} onChange={(event) => {
                                                            setFieldValue("tipoComisionId", event.value);
                                                            handleChange(event, values);
                                                            handleChageProgress(errors, isValid);
                                                        }} />
                                                    </div>
                                                }

                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="TipoCompra" disabled={role === "DMSocioComercial" ? true : values?.ventaContado  ? values?.ventaContado : disabledByVIN || disabledFields} label="Tipo compra:" name="tipoCompraId" items={dataTipoCompra} onChange={(event) => {
                                                        setFieldValue("tipoCompraId", event.value);
                                                        handleChangeTipoCompra(event, values);
                                                        handleChageProgress(errors, isValid);
                                                        // setInitialValue({ ...valuesState, isMontoFijo: true, tipoComisionId: value.value, comision: "" });
                                                    }} />
                                                </div>
                                                {
                                                    values.isSocioComercial &&
                                                    <div className="col-sm-12 col-md-6 col-lg-3">

                                                        <SelectField id="SocioComercial" label="Socio comercial:" name="usrSocioComercialId" disabled={role === "DMSocioComercial" ? true : disabledByVIN || disabledFields} items={dataSocioComercial} onChange={(event) => {
                                                            setFieldValue("usrSocioComercialId", event.value);
                                                            handleChageProgress(errors, isValid);
                                                        }} />
                                                    </div>
                                                }
                                                {
                                                    isconsignacion &&
                                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                                        {/* disabled={!activeComision} */}
                                                        <TextField id="Comision" label="comisión:" name="comision" disabled={disabledByVIN || disabledFields} type="text" preffix="$" holder="Escribe" onChange={(event) => {
                                                            setFieldValue("comision", FormatNumberMoney(event.target.value));
                                                            handleChageProgress(errors, isValid);
                                                        }} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <p> <strong>Proveedor</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="Proveedor" label="Proveedor:" name="proveedor" disabled={disabledByVIN || disabledFields} type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("proveedor", event.target.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="TelefonoProveedor" label="Telefono proveedor:" name="telProveedor" disabled={disabledByVIN || disabledFields} type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("telProveedor", handlePhonNumberFormatter(event.target.value));
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="CorreoProveedor" label="Correo proveedor:" name="emailProveedor" disabled={disabledByVIN || disabledFields} type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("emailProveedor", event.target.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <p> <strong>Pago</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="TipoPago" label="Tipo pago:" name="tipoPagoId"  disabled={disabledByVIN || disabledFields}items={dataTipoPago} onChange={(event) => {
                                                        setFieldValue("tipoPagoId", event.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="NumeroPago" label="Numero pago:" name="numeroPago" disabled={disabledByVIN || disabledFields} type="text" holder="Escribe" onChange={(event) => {
                                                        setFieldValue("numeroPago", event.target.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField id="TipoFactura" label="Tipo factura:" name="tipoFacturaId" disabled={disabledByVIN || disabledFields} items={dataTipoFactura} onChange={(event) => {
                                                        setFieldValue("tipoFacturaId", event.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField id="CostoVehiculo" label="Costo vehiculo:" icon="bi bi-currency-dollar" isMoney={true} name="costo" disabled={disabledByVIN || disabledFields}  holder="Escribe" onChange={(event) => {
                                                        setFieldValue("costo", event.target.value);
                                                        handleChageProgress(errors, isValid);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                }
                                {
                                    value === 2 &&
                                    <Form>
                                        <section className='d-flex justify-content-between py-2'>
                                            <span className='fs-5'><strong>Información del vehiculo</strong></span>
                                            <button className='btn mx-2' type="submit" >{id == 0 ? "Guardar" : "Editar"}</button>
                                            {/* disabled={!(isValid)} */}
                                        </section>
                                        <div className='my-2'>
                                            <p> <strong>Caracteristicas del vehículo</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Transmición:" name="estadoId" items={[]} onChange={(event) => {
                                                        setFieldValue("estadoId", event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="No. Motor:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Color:" name="banco" type="color" holder="Escribe" />

                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Kilometraje:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Precio lista:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-2'>
                                            <p> <strong>Entidad placas</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Aplica:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Entidad:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Costo entidad placas:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-2'>
                                            <p> <strong>Tenencia y cambio de propietario</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Tenencia:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="cambio propietario:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-2'>
                                            <p> <strong>Responsiva</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Responsiva:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Tipo responsiva:" name="estadoId" items={[]} onChange={(event) => {
                                                        setFieldValue("estadoId", event.value);
                                                    }} />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Monto a financiar:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-2'>
                                            <p> <strong>Número datamovil</strong></p>
                                            <div className="mb-3 row">
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <TextField label="Numero de serie:" name="banco" type="text" holder="Escribe" />
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-3">
                                                    <SelectField label="Estatus:" name="estadoId" items={[]} onChange={(event) => {
                                                        setFieldValue("estadoId", event.value);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                }

                            </div>

                        </section>
                    </div>
                </>
            )
            }
        </Formik>
    )
}

export default AddEditVehiculo