import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { message, pageRows } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CrearFolderModal from '../../Ventas/ModalCreateFolder'
import useSeguroAutorizadoPoliza from '../../../Hooks/Seguros/useSeguroAutorizadoPoliza'
import SubMenuInstalacion from './SubmenuInstalacion'
import useInstaladoEnInventario from '../../../Hooks/Inventario/useInstaladoEnInventario'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const VehiculosDeInventario = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        tipoCompra,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        setclaveUbicacion,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        setTipoCompra,
        DataUbicacion,
        dataStatus,
        setClaveStatus,
        handleDownloadFile
    } = useInstaladoEnInventario(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuInstalacion />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos pendientes del Datamovil</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosDeInventario, SubModule.InstalaciónDatamovil).Descarga}>
                        <button className='btn' onClick={handleDownloadFile}>Exportar Excel</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos de inventario y consignación pendientes del Datamovil.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosDeInventario, SubModule.InstalaciónDatamovil).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setTipoCompra(e.value) }} placeholder="Tipo compra" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataUbicacion} className='col-12' onChange={(e) => { setclaveUbicacion(e.value) }} placeholder="Ubicación" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={dataStatus} className='col-12' onChange={(e) => { setClaveStatus(e.value) }} placeholder="Estatus datamovil" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        editable={ValidatePermissionById(4, Section.VehículosDeInventario, SubModule.InstalaciónDatamovil).Actualizar}
                        handleEdit={handleEdit}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    // checkbox={true}
                    // handleCheck={handlechekedItem}
                    />
                </div>
            </ValidatePermission>
            {
                isOpenDocs &&
                <CrearFolderModal
                    isOpen={isOpenDocs}
                    setIsOpen={setIsOpenDocs}
                    item={dataVehiculo}
                    handleGetAll={habdleGetAll}
                    message={message}
                    toast={toast}
                />
            }
        </>
    )
}

export default VehiculosDeInventario