import React from 'react'
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import toast, { Toaster } from 'react-hot-toast'
import usePruebaManejo from '../../../Hooks/Inventario/usePruebaManejo'
import { pageRows } from '../../../Utils/functions'
import MantenerPruebaManejo from './MantenerPruebaManejoModal'
import LiberarPruebaManeModal from './LiberarPruebaModal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const PruebaManejo = () => {
    const { columns,
        columnsH,
        data,
        handlePageClick,
        currenPage,
        pageCount,
        pageCountModal,
        setSucursal,
        setStr,
        loading,
        dataSucursal,
        setIsOpenModal,
        IsOpenModal,
        handlePageClickH,
        currenPageH,
        dataUtilitario,
        setUtilitarioId,
        handlechekedItem,
        setpageRows,
        handleModalTDrive,
        hanldleHistoryTDrive,
        habdleGetAll,
        isOpenModalTDrive,
        setIsOpenModalTDrive,
        isOpenModalTDriveEnd,
        setisOpenModalTDriveEnd,
        setStrH,
        DataHistory,
        dataItem,
        handleLiberarPruebaManejo,
        refBtnExp,
        handleLiberarPruebaCheck,
        isOpenModalLiberar,
        setIsOpenModalLiberar,
        handleModalLiberarCheck,
        pageCountH,
        IdsEliminar,
        dataEndTD,
        estadoVehiculo,
        setEstadoVehiculo,
        handleUploadFile,
        item
    } = usePruebaManejo(toast);
    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoDePruebaDeManejo, SubModule.PruebaMeManejo).Ver}>
                <section className='mx-3 py-4 d-flex flex-column'>
                    <div className='d-flex justify-content-between'>
                        <span className='fs-5'><strong>Seguimiento de prueba de manejo</strong></span>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.SeguimientoDePruebaDeManejo, SubModule.PruebaMeManejo).Liberar}>
                            <div>
                                <button className='btn px-4' onClick={() => { handleModalLiberarCheck() }}>Liberar prueba</button>
                            </div>
                        </ValidatePermission>
                    </div>
                    <span className='mt-2'>El siguiente listado corresponde a los vehículos en prueba de manejo y su configuración.</span>
                </section>
                <section className='mx-4'>
                    <div className='row col-12'>
                        <div className="col-lg-3 col-mg-6 col-sm-12 mt-2 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
                            <Select options={dataSucursal} className='col-12' onChange={(e) => { setSucursal(e.value) }} placeholder="Sucursal" />
                        </div>
                        <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
                            <Select options={dataUtilitario} className='col-12' onChange={(e) => { setUtilitarioId(e.value) }} placeholder="Estado de prueba" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        type={2}
                        checkbox={true}
                        handleCheck={handlechekedItem}
                        testDriverMantener={ValidatePermissionById(4, Section.SeguimientoDePruebaDeManejo, SubModule.PruebaMeManejo).Mantener}
                        testDriverLiberar={ValidatePermissionById(4, Section.SeguimientoDePruebaDeManejo, SubModule.PruebaMeManejo).Concluir}
                        testDriverHistorial={ValidatePermissionById(4, Section.SeguimientoDePruebaDeManejo, SubModule.PruebaMeManejo).Historial}
                        handleTDrive={handleModalTDrive}
                        handleEndTDrive={handleUploadFile}
                        handleHistoryTDrive={(item) => { hanldleHistoryTDrive(null, item) }}
                    />
                </div>
            </ValidatePermission>
            {
                isOpenModalTDrive &&
                <MantenerPruebaManejo
                    isOpen={isOpenModalTDrive}
                    setIsOpen={() => { setIsOpenModalTDrive(false) }}
                    item={dataItem}
                    handleGetAll={habdleGetAll}
                    toast={toast}
                />
            }
            {
                isOpenModalTDriveEnd &&
                <Modal isOpen={isOpenModalTDriveEnd} color='#fff'>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <div className='d-flex flex-column'>
                            <span className='fs-5 mb-2'><strong>Estado de vehículo</strong></span>
                            <span style={{ fontSize: '14px' }}>A continuación indica si la prueba de manejo se mantendrá nuevamente o si la unidad será liberada.</span>
                        </div>
                        <section className='my-4'>
                            <span className='fs-6'>Estado de vehiculo</span>
                            <Select options={dataEndTD} className='col-12' defaultValue={dataEndTD[0]} onChange={(e) => { setEstadoVehiculo(e.value) }} placeholder="Seleccionar" />
                        </section>
                        <section className='my-3 d-flex justify-content-end'>
                            <fieldset className='btn btn-outline px-5' type='reset' onClick={() => { setisOpenModalTDriveEnd(false) }}>Cerrar</fieldset>
                            <button className='btn mx-2 px-5' onClick={() => {
                                if (estadoVehiculo === 0) {
                                    // handleLiberarPruebaManejo();
                                    setIsOpenModalLiberar(true)
                                    setIsOpenModalTDrive(false)
                                    setisOpenModalTDriveEnd(false);
                                } else {
                                    setisOpenModalTDriveEnd(false);
                                    setIsOpenModalTDrive(true)
                                }
                            }}>Continuar</button>
                        </section>

                    </section>
                </Modal>
            }
            {/* {
                isOpenModalLiberar &&
                <Modal isOpen={isOpenModalLiberar} color='#fff'>
                    <section className='my-4 mx-4' style={{ width: '30rem' }}>
                        <div className='d-flex flex-column'>
                            <span className='fs-5 mb-2'><strong>Liberar prueba</strong></span>
                            <span style={{ fontSize: '14px' }}>¿Seguro que deseas liberar {IdsEliminar.length > 1 ? "los" : "el"} <strong>{IdsEliminar.length > 1 ? `${IdsEliminar.length} vehículos seleccionados` : "vehículo seleccionado"}</strong>? <br /> Los vehículos se liberarán de la prueba de manejo por lo que no los podrás encontrar en el mismo listado.</span>
                        </div>
                        <section className='my-3 d-flex justify-content-end'>
                            <fieldset className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpenModalLiberar(false) }}>Cerrar</fieldset>
                            <button className='btn mx-2 px-5' ref={refBtnExp} onClick={() => { handleLiberarPruebaCheck() }}>Continuar</button>
                        </section>

                    </section>
                </Modal>
            } */}
            {
                isOpenModalLiberar &&
                <LiberarPruebaManeModal
                    isOpen={isOpenModalLiberar}
                    setIsOpen={setIsOpenModalLiberar}
                    item={{ ...item, separado: false }}
                    handleGetAll={habdleGetAll}
                    toast={toast}
                />
            }
            {
                IsOpenModal &&
                <Modal isOpen={IsOpenModal} color='#fff'>
                    <section className='my-4 mx-4' style={{ width: '80vw' }}>
                        <div>
                            <span className='fs-6'><strong>Historial prueba de manejo</strong></span>
                        </div>
                        <section className=''>
                            <div className='row col-12'>
                                <div className="col-lg-4 col-mg-6 col-sm-12 mt-2 inner-addon right-addon">
                                    <i className="glyphicon fas fa-search"></i>
                                    <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { setStrH(e.target.value) }} />
                                </div>
                            </div>
                        </section>
                        <DataTable
                            column={columnsH}
                            data={DataHistory}
                            pageCount={pageCountH}
                            handlePageClick={handlePageClickH}
                            currentPage={currenPageH - 1}
                        />
                        <section className='my-3 d-flex justify-content-end'>
                            <fieldset className='btn px-5' type='reset' onClick={() => { setIsOpenModal(false) }}>Cerrar</fieldset>
                            {/* <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button> */}
                        </section>

                    </section>
                </Modal>
            }
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

        </>
    )
}

export default PruebaManejo