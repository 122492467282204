import React from 'react'
import DataTable from '../../../Components/datatable/DataTable'
import Select from 'react-select'
import Modal from '../../../Components/Modal/Modal'
import Spinner from '../../../Components/Loadig'
import toast, { Toaster } from 'react-hot-toast'
import useInventarioSocioComercial from '../../../Hooks/Inventario/useInventarioSocioComercial'
import VehiculoUtilitarioModal from '../../Ventas/ModalVehiculoutilitario'
import { useSelector } from 'react-redux'
import { pageRows } from '../../../Utils/functions'
import RowPages from '../../../Components/RowPages'
import ModalConfirmVehiculoConvertido from '../VehiculoConvertido/ModalConfirmVehiculoConvertido'
import { validateManyPermissions, ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const InvSocioComercial = () => {
  const { item, isOpenModalClaveCambioProd } = useSelector((state) => state.quickActionsSlice);
  const { columns,
    columnsH,
    data,
    handleEdit,
    navigate,
    handlePageClick,
    currenPage,
    pageCount,
    setTipoAd,
    setStr,
    loading,
    dataTipoAdquisicion,
    handleCreateFolder,
    setIsOpenDocs,
    isOpenDocs,
    dataVehiculo,
    habdleGetAll,
    setIsOpenModal,
    dataModal,
    IsOpenModal,
    handlePageClickH,
    currenPageH,
    dtaSocioComercial,
    setUtilitarioId,
    handleViewdocs,
    handleUnchekedColumns,
    DataEtapa,
    setEtapaId,
    setClaveSocioComercial,
    setpageRows,
    role
  } = useInventarioSocioComercial(toast);

  const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: '#47a066',
              color: '#FFFF',
              borderLeft: '10px solid #2f7246'
            },
          },
          error: {
            style: {
              background: '#d53f3f',
              color: '#FFFF',
              borderLeft: '10px solid #ac241a'
            },
          },
        }}
      />
      <section className='mx-3 py-4 d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Vehículos Socio Comercial</strong></span>
          <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculosSocioComercial, SubModule.SocioComercial).Agregar}>
            <div>
              <button className='btn px-4' onClick={() => {
                navigate(`/inventario/vehiculo/prealta/0`, {
                  state: {
                    page: 'Vehiculo',
                    title: "Nuevo Vehiculo",
                    etapa: 0,
                    esSocioComercial: true
                  },
                });
              }}>Nuevo vehículo</button>
            </div>
          </ValidatePermission>
        </div>
        <span className='mt-2'>El siguiente listado corresponde a los vehículos registrados y su etapa actual.</span>
      </section>
      <ValidatePermission isActive={ValidatePermissionById(4, Section.VehiculosSocioComercial, SubModule.SocioComercial).Ver}>
        <section className='mx-4'>
          <div className='row col-12'>
            <div className="col-lg-3 col-mg-6 col-sm-12 mt-2 inner-addon right-addon">
              <i className="glyphicon fas fa-search"></i>
              <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === "Enter" && setStr(e.target.value) }} />
            </div>
            <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
              <Select options={DataEtapa} className='col-12' onChange={(e) => { setEtapaId(e.value) }} placeholder="Etapa" />
            </div>
            {
              role === "Compras" || role === "Administrador" ?
                <div className='col-lg-3 col-mg-6 col-sm-12 mt-2'>
                  <Select options={dtaSocioComercial} className='col-12' onChange={(e) => { setClaveSocioComercial(e.value) }} placeholder="Socio comercial" />
                </div> : null
            }

          </div>
        </section>
        <RowPages
          setpageRows={setpageRows}
        />
        <div className='mx-4 table-responsive'>
          <DataTable
            column={columns}
            data={data}
            editable={ValidatePermissionById(4, Section.VehiculosSocioComercial, SubModule.SocioComercial).Actualizar}
            handleEdit={handleEdit}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            currentPage={currenPage - 1}
            checkbox={validateManyPermissions(["Agregar","Actualizar","Ver"],ValidatePermissionById(4, Section.VehiculosSocioComercial, SubModule.SocioComercial))}
            handleCheck={handleUnchekedColumns}
            handleDocuments={handleViewdocs}
          />
        </div>
      </ValidatePermission>
      {
        isOpenDocs &&
        <VehiculoUtilitarioModal
          isOpen={isOpenDocs}
          setIsOpen={setIsOpenDocs}
          item={dataVehiculo}
          handleGetAll={habdleGetAll}
          message={message}
          toast={toast}
        />
      }
      {
        IsOpenModal &&
        <Modal isOpen={IsOpenModal} color='#fff'>
          <section className='my-4 mx-4' style={{ width: '50rem' }}>
            <div className='d-flex justify-content-center fs-6'>
              <span className='fs-5'><strong>Historial Utilitario</strong></span>

            </div>

            <DataTable
              column={columnsH}
              data={dataModal}
              pageCount={pageCount}
              handlePageClick={handlePageClickH}
              currentPage={currenPageH - 1}
            />
            <section className='my-3 d-flex justify-content-end'>
              <fieldset className='btn btn-outline px-5' type='reset' onClick={() => { setIsOpenModal(false) }}>Cancelar</fieldset>
              {/* <button className='btn mx-2 px-5' disabled={!(isValid)}>Continuar</button> */}
            </section>

          </section>
        </Modal>
      }
      {
        isOpenModalClaveCambioProd &&
        <ModalConfirmVehiculoConvertido
          toast={toast}
          isOpenModalClave={isOpenModalClaveCambioProd}
          item={item}
        />
      }
      <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>

    </>
  )
}

export default InvSocioComercial