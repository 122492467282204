import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import SubmenuDatadocs from './SubMenuDatadocs'
import Modal from '../../Components/Modal/Modal'
import Spinner from '../../Components/Loadig'
import DataTable from '../../Components/datatable/DataTable'
import CrearFolderModal from '../Ventas/ModalCreateFolder'
import useDataDocs from '../../Hooks/DataDocs/useDataDocs'
import VentaVehiculoService from '../../Services/Venta/Vehiculo/ventaVehiculoService'
import Catalogo from '../../Services/Catalogos/Catalogo'
import EtapaService from '../../Services/ControlEtapa/confEtapa'
import DocumentoUsuarioModal from './ModalDocumentoUsuario'
import useDocumentoporRol from '../../Hooks/AdminDataDocs/useDocumentoporRol'
import { pageRows } from '../../Utils/functions'
import RowPages from '../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../Auth/ValidatePermission'
import { Section, SubModule } from '../../Auth/Authorization'
const TipoDocumentoUsuario = () => {

    const {
        habdleGetAll,
        dataVehiculo,
        setIsOpenDocs,
        isOpenDocs,
        handleDeleteItem,
        currenPage,
        handlePageClick,
        pageCount,
        handleCreateFolder,
        data,
        columns,
        setDataVehiculo,
        setRolId,
        dataRol,
        setStr,
        loading,
        setpageRows
    } = useDocumentoporRol(toast);

    const message = (title, message) => <span style={{ fontSize: '13px', margin: '0px' }}><strong>{title}</strong> <br /> <p style={{ fontSize: '12px', margin: '0px' }}>{message}</p></span>
    return (
        <>
            <SubmenuDatadocs />
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                {/* <div className='d-flex justify-content-between'>
          <span className='fs-5'><strong>Folder DataDocs</strong></span>
        </div> */}
                <span className='mt-2'>El siguiente listado corresponde a la configuración de documentos por usuario.</span>
            </section>
            <section className='mt-3 headerTab pb-4'>
                <div className='ms-3'>
                    <div className='row col-12'>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <label>Buscar</label>
                            <div className="col-12 inner-addon right-addon">
                                <i className="glyphicon fas fa-search"></i>
                                <input type="text" className="form-control" placeholder="Buscar" onKeyDown={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <label>Rol</label>
                            <Select options={dataRol} className='col-12' placeholder="Seleccione" onChange={(e) => { setRolId(e.value) }} />
                        </div>
                        <ValidatePermission isActive={ValidatePermissionById(4, Section.DocumentosPorUsuario, SubModule.DataDocs).Agregar}>
                            <div className='d-flex flex-column justify-content-end my-1 col-lg-2 col-md-12 col-sm-12'>
                                <button className='btn' onClick={() => { setDataVehiculo({}); setIsOpenDocs(true); }}>Asignar</button>
                            </div>
                        </ValidatePermission>
                    </div>
                </div>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.DocumentosPorUsuario, SubModule.DataDocs).Ver}>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>

                    <DataTable
                        column={columns}
                        data={data}
                        editable={ValidatePermissionById(4, Section.DocumentosPorUsuario, SubModule.DataDocs).Actualizar}
                        handleEdit={handleCreateFolder}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                        deleteable={ValidatePermissionById(4, Section.DocumentosPorUsuario, SubModule.DataDocs).Ver}
                        handleDelete={handleDeleteItem}
                    />
                </div>
            </ValidatePermission>
            {
                isOpenDocs &&
                <DocumentoUsuarioModal
                    isOpen={isOpenDocs}
                    setIsOpen={setIsOpenDocs}
                    item={dataVehiculo}
                    handleGetAll={()=>{habdleGetAll(currenPage)}}
                    message={message}
                    toast={toast}
                />
            }
        </>
    )
}

export default TipoDocumentoUsuario