import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import RowPages from '../../../Components/RowPages'
import useVehiculoEntrega from '../../../Hooks/Inventario/useVehiculoEntrega'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
import SubMenuEntrega from './SubMenuEntrega'

const VehiculoEnCancelacionPendiente = () =>{
    const { 
        columnsPendientes,
        dataPendientes,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setpageRows,
        DataUbicacion,
        tipoCompra,
        setclaveTipoCompra,
        setClaveUbicacion,
        handleUploadFile,
        handleCapturaCancelacion,
        setFechaInicio,
        setFechaFin
    } = useVehiculoEntrega(2);

    const [dataEntrega, setDataEntrega] = React.useState({});

    const handleEntrega = async(data) =>{
        setDataEntrega(data);
        console.log("data entrega", data);

        if(data.estatus == "Capturar información")
                handleCapturaCancelacion(data);
            else if(data.estatus == "En espera acendes")
                handleCapturaCancelacion(data);
            else if(data.estatus == "Carga de evidencias")
                handleUploadFile(data, 0);
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuEntrega/>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos pendientes por cancelar entrega</strong></span>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos pendientes por cancelar entrega.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.PendientesPorCancelar, SubModule.Entrega).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 inner-addon right-addon d-flex flex-column-reverse">
                            {/* <label htmlFor=""></label> */}
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label htmlFor=""></label>
                            <Select options={DataUbicacion} className='col-12' onChange={(e) => { setClaveUbicacion(e.value) }} placeholder="Ubicación" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 px-1'>
                            <label htmlFor=""></label>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setclaveTipoCompra(e.value) }} placeholder="Tipo de compra" />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <label>Fecha Inicio</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechaInicio(e.target.value) }} />
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 px-1 form-group">
                            <label>Fecha fin</label>
                            <input type="date" className="form-control" placeholder="Buscar" onChange={(e) => { setFechaFin(e.target.value) }} />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columnsPendientes}
                        data={dataPendientes}
                        pageCount={pageCount}
                        detailable={ValidatePermissionById(4, Section.PendientesPorCancelar, SubModule.Entrega).Actualizar}
                        handleDetail={handleEntrega}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    />
                </div>
            </ValidatePermission>
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
        </>
    )
}

export default VehiculoEnCancelacionPendiente