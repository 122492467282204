import React, { useState } from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { message, pageRows } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import SubMenuGestoria from './SubMenuGestoria'
import useInventarioGestoriaPP from '../../../Hooks/Inventario/useInventarioGestoriaPP'
import useVehiculoPendientePlaca from '../../../Hooks/Inventario/useVehiculoPendientePlaca'
import RowPages from '../../../Components/RowPages'
import useGestoriaCancelada from '../../../Hooks/Inventario/useGestoriaCancelada'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const CancelacionContratoGestoria = () => {
    const { columns,
        data,
        handleEdit,
        handlePageClick,
        currenPage,
        pageCount,
        setStr,
        loading,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        habdleGetAll,
        setpageRows,
        handlechekedItem,
        handleUploadFile,
        tipoCompra,
        setclaveTipoCompra,
        handleDownloadFile,
        handleCapturaCancelacion,
        handleSaveGestoriaRealizada,
        handleSaveGestoriaCancelada
    } = useGestoriaCancelada(toast);

    const [dataGestion, setDataGestion] = useState([]);

    const [gestoria, setGestoria] = useState("");
    const [isOpenModal, setIsOpenModal] = useState(false);


    const handleGestoria = (data) => {
        console.log("data",data)
        setDataGestion(data);

        if (data.gestoria == "Sin definir")
            setIsOpenModal(true);
        else if (data.gestoria == "Realizada") {
            if (data.estatus == "Carga de evidencia")
                handleUploadFile(data, 1);
        } else if (data.gestoria == "Cancelada") {
            if (data.estatus == "Capturar información")
                handleCapturaCancelacion(data);
            else if (data.estatus == "En espera acendes")
                handleCapturaCancelacion(data);
            else if (data.estatus == "Carga de evidencia")
                handleUploadFile(data, 0);
        }
    }
    const handleSelectSeguimiento = async () => {
        try {
            if (gestoria == "1") {
                let data = {
                    generalId: dataGestion.generalId,
                }
                let result = await handleSaveGestoriaRealizada(data);

                if (result.ban == 1) {
                    handleUploadFile(dataGestion, 1);
                }
            } else if (gestoria == "2") {

                let data = {
                    generalId: dataGestion.generalId,
                }

                const result = await handleSaveGestoriaCancelada(data);

                if (result.ban == 1) {
                    handleCapturaCancelacion(dataGestion);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <SubMenuGestoria />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Vehículos cancelados por tiempo</strong></span>
                    {/* <button className='btn'  onClick={handleDownloadFile}>Exportar Excel</button> */}
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos que su gestoría ha sido cancelada por tiempo pero están pendientes de subir evidencia.</span>
            </section>
            <ValidatePermission isActive={ValidatePermissionById(4, Section.CancelaciónDeContratoPorGestoría, SubModule.GestoríaDeVehículos).Ver}>
                <section className=''>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === "Enter" && setStr(e.target.value) }} />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={tipoCompra} className='col-12' onChange={(e) => { setclaveTipoCompra(e.value) }} placeholder="Tipo compra" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        detailable={ValidatePermissionById(4, Section.CancelaciónDeContratoPorGestoría, SubModule.GestoríaDeVehículos).Actualizar}
                        handleDetail={handleGestoria}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    />
                </div>
            </ValidatePermission>
        </>
    )
}

export default CancelacionContratoGestoria