import React from 'react'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import useDataDocs from '../../../Hooks/DataDocs/useDataDocs'
import { message, pageRows } from '../../../Utils/functions'
import DataTable from '../../../Components/datatable/DataTable'
import Spinner from '../../../Components/Loadig'
import Modal from '../../../Components/Modal/Modal'
import CrearFolderModal from '../../Ventas/ModalCreateFolder'
import useSeguroCotizado from '../../../Hooks/Seguros/useSeguroCotizado'
import useCotizacionMensual from '../../../Hooks/Seguros/useCotizacionMensual'
import SubmenuRenovacionSeguro from './SubmenuRenovacionSeguro'
import useVehiculoContrato from '../../../Hooks/Seguros/useVehiculoContrato'
import useSeguroUtilitario from '../../../Hooks/Seguros/useSeguroUtilitario'
import ErrorModal from '../../../Components/Modal/ErrorModal'
import SuccessModal from '../../../Components/Modal/SuccessModal'
import RowPages from '../../../Components/RowPages'
import { ValidatePermission, ValidatePermissionById } from '../../../Auth/ValidatePermission'
import { Section, SubModule } from '../../../Auth/Authorization'
const RenovacionUtilitario = () => {
    const { columns,
        data,
        handleEdit,
        navigate,
        handlePageClick,
        currenPage,
        pageCount,
        setTipoAd,
        setStr,
        loading,
        DataMarca,
        handleCreateFolder,
        setIsOpenDocs,
        isOpenDocs,
        dataVehiculo,
        setfiltroDocs,
        habdleGetAll,
        handleChangeMarca,
        handleChangeModelo,
        handleChangeYear,
        setClaveVersion,
        DataModelo,
        DataYear,
        DataVersion,
        DataUsoUnidad,
        setClaveUsoUnidad,
        setpageRows,
        handleCheck,
        handleDownloadFile,
        refBtn,
        modalMessage,
        setModalMessage,
        defaultValueModelo,
        defaultValueYear
    } = useSeguroUtilitario();
    return (
        <>
            <Modal isOpen={modalMessage.isOpen} color='#fff'>
                {
                    modalMessage.type === 1 &&
                    <ErrorModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }
                {
                    modalMessage.type === 2 &&
                    <SuccessModal
                        modalMessage={modalMessage}
                        setModalMessage={setModalMessage}
                    />
                }

            </Modal>
            <Toaster
                position="top-right"
                toastOptions={{
                    success: {
                        style: {
                            background: '#47a066',
                            color: '#FFFF',
                            borderLeft: '10px solid #2f7246'
                        },
                    },
                    error: {
                        style: {
                            background: '#d53f3f',
                            color: '#FFFF',
                            borderLeft: '10px solid #ac241a'
                        },
                    },
                }}
            />
            <Modal isOpen={loading} color='transparent'><Spinner message={""} /></Modal>
            <SubmenuRenovacionSeguro />
            <section className='mx-3 py-4 d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                    <span className='fs-5'><strong>Renovación de seguro</strong></span>
                    <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosUtilitarios, SubModule.RenovaciónDeSeguros).Descarga}>
                        <button className='btn px-4 me-1' ref={refBtn} onClick={handleDownloadFile}>Exportar Excel</button>
                    </ValidatePermission>
                </div>
                <span className='mt-2'>El siguiente listado corresponde a los vehículos utilitarios con la opción de configurar su seguro.</span>
            </section>
            {/* <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosUtilitarios, SubModule.RenovaciónDeSeguros).Ver}> */}
            <ValidatePermission isActive={ValidatePermissionById(4, Section.VehículosUtilitarios, SubModule.RenovaciónDeSeguros).Ver}>
                <section className='pb-4'>
                    <div className='row col-12 px-4'>
                        <div className="col-lg-2 col-md-6 col-sm-12 my-1 px-1 inner-addon right-addon">
                            <i className="glyphicon fas fa-search"></i>
                            <input type="text" className="form-control mb-4" placeholder="Buscar" onKeyUp={(e) => { e.key === 'Enter' && setStr(e.target.value) }} />
                        </div>
                        {/* <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                        <Select options={DataUsoUnidad} className='col-12' onChange={(e) => { setClaveUsoUnidad(e.value) }} placeholder="Uso unidad" />
                    </div> */}
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataMarca} className='col-12' onChange={(e) => { handleChangeMarca(e.value) }} placeholder="Marca" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataModelo} value={defaultValueModelo} className='col-12' onChange={(e) => { handleChangeModelo(e) }} placeholder="Modelo" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataYear} value={defaultValueYear} className='col-12' onChange={(e) => { handleChangeYear(e) }} placeholder="Año" />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 my-1 px-1'>
                            <Select options={DataUsoUnidad} className='col-12' onChange={(e) => { setClaveUsoUnidad(e.value) }} placeholder="Uso unidad" />
                        </div>
                    </div>
                </section>
                <RowPages
                    setpageRows={setpageRows}
                />
                <div className='mx-4 table-responsive'>
                    <DataTable
                        column={columns}
                        data={data}
                        editable={ValidatePermissionById(4, Section.VehículosUtilitarios, SubModule.RenovaciónDeSeguros).Actualizar}
                        // checkbox={true}
                        // handleCheck={handleCheck}
                        handleEdit={handleEdit}
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                        currentPage={currenPage - 1}
                    />
                </div>
            </ValidatePermission>
            {
                isOpenDocs &&
                <CrearFolderModal
                    isOpen={isOpenDocs}
                    setIsOpen={setIsOpenDocs}
                    item={dataVehiculo}
                    handleGetAll={habdleGetAll}
                    message={message}
                    toast={toast}
                />
            }
        </>
    )
}

export default RenovacionUtilitario